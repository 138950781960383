import setupCounter from "./countDown.js";
import './tooltip.js'

const loading = document.querySelector(".loading-page")
const HASHED_PASS = "912df38fe384db9fe00ce18570a569561a76a48acfaaffa01a859df4a9bcb66a"

if (localStorage.getItem("password") === HASHED_PASS) {
    setupCounter()
} else {
    setupLogin()
}

addEventListener("load", () => {
    setTimeout(() => loading.classList.add("loading-page--hide"), 4000)
})


function setupLogin() {
    location.href = './login'
}
